@media print {
  @page {
    size: A4 landscape;
    page-break-before: auto;
  }

  html,
  body {
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  header {
    height: 62px;
    .title-wrap {
      height: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
      }
      .btn-wrap {
        margin-left: auto;
      }
    }
  }

  .print-wrap {
    // 테이블 스타일
    table {
      td,
      th {
        &.tc-125 {
          width: 125px;
        }
        height: 32px;
        border-bottom: 1px solid #ccc;
      }
    }

    .ResultOverview,
    .FloorSummary,
    .HouseholdSummary,
    .BusinessFeasibility {
      tbody {
        tr {
          &:last-child {
            td {
              border-bottom: 1px solid #00897b;
            }
          }
        }
      }

      thead {
        tr {
          th {
            border-bottom: 1px solid #00897b;
          }
        }
      }
      tfoot {
        tr {
          td,
          th {
          }
        }
      }
    }
    .MuiTable-root {
      box-sizing: border-box;
      .MuiTableCell-root {
        box-sizing: border-box;
        letter-spacing: -0.05em;
      }
      .table-head {
        .td {
          height: 32px;
        }
        border-top: 2px solid #00897b;
      }
      .tr {
        height: 32px;
        letter-spacing: -0.05em;
        box-sizing: border-box;
      }
      .th {
        color: rgba(51, 51, 51, 0.87);
        font-weight: bold;
        font-size: 14px;
        padding: 0 0 0 10px;
        &.highlight {
          color: rgba(0, 137, 123, 0.87);
        }
        &.sub-th {
          padding-left: 19.5px;
          font-size: 12px;
        }
      }
      .td {
        box-sizing: border-box;
        padding: 0 0 0 10px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        &.sub-td {
          font-size: 12px;
        }
      }
      .MuiTableCell-root.no-padding {
        padding: 0;
      }
    }
    section {
      page-break-before: always;
      width: 1060px;
      height: 695px;
      padding: 30px;
      box-sizing: border-box;
      margin: calc((210mm - 608px - 100px) / 2) auto;
    }
    .not-print {
      .MuiIconButton-label {
        display: none;
      }
      display: none;
    }
    .print-only {
      display: block;
      .sub-title-header {
        height: 24px;
        color: rgba(51, 51, 51, 0.87);
        font-size: 16px;
      }
    }
    /* 프린트 1. 사업성 검토 요약 */
    .ResultOverview {
      > .sub-title {
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
        margin: 30px auto 0px auto;
      }
      .inner-section {
        article:nth-child(2) {
          margin-left: 20px;
        }
        display: flex;
        .MuiTable-root {
          box-sizing: border-box;
          width: 490px;
          .tr {
            height: 32px;
          }
        }
      }
      .table-title {
        color: rgba(0, 0, 0, 0.87);
        font-weight: bold;
        font-size: 14px;
        padding: 4px 0;
        height: 24px;
        margin-top: 20px;
        line-height: 1.5em;
      }
    }
    /* 2. 대지현황 - 프린트 */
    .SiteAnalysis {
      .sub-title {
        margin: 30px auto 30px auto;
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
      }
      .content-wraper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }
    /* 3. 건축개요 - 프린트 */
    .ArchitectureOverview {
      .sub-title {
        margin: 30px auto 30px auto;
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
      }
      .content-wraper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    /* 4. 층별 평면도 - 프린트 */
    .FloorDivision {
      .sub-title {
        margin: 30px auto 30px auto;
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
      }
      .content-wraper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
      .MuiTableContainer-root {
        width: 1000px;
      }
      .result-img {
        width: auto;
        height: 374px;
        box-sizing: border-box;
        border: 2px solid #00897b;
        margin: 10px;
      }
    }
    /* 4-1. 오피스텔 층별 평면도 - 프린트 */
    .OfficeFloorDivision {
      .sub-title {
        margin: 30px auto 30px auto;
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
      }
      .MuiTableContainer-root {
        width: 1000px;
      }
      .content-wraper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
      .result-img {
        width: 300px;
        height: 300px;
        box-sizing: border-box;
        border: 2px solid #00897b;
        margin: 10px;
      }
    }

    /* 5. 층별개요 - 프린트 */
    .FloorSummary {
      .sub-title {
        margin: 30px auto 20px auto;
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
      }
    }

    /* 6. 세대별 개요 - 프린트 */
    .HouseholdSummary {
      .sub-title {
        margin: 30px 0 20px 0;
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
        &.margin-30 {
          margin-top: 30px;
        }
        &.margin-40 {
          margin-top: 40px;
        }
      }
      .MuiTable-root {
        margin-bottom: 0px !important;
      }
    }
    /* 7. 사업성 검토 - 프린트*/
    .BusinessFeasibility {
      .sub-title {
        font-weight: bold;
        color: rgba(0, 137, 123, 0.87);
        margin: 30px 0 20px 0;
      }
      .content-wraper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}

//----print end-------

.ResultInfo {
  button {
    cursor: pointer;
  }

  .MuiTableCell-head {
    font-weight: normal;
  }

  font-family: Roboto;
  overflow-y: scroll;
  height: calc(100% - 40px);
  width: 1060px;
  margin: auto;
  height: 570px;
  section {
    margin-bottom: 80px;
  }

  // 단위 변환 버튼
  .unit-btns {
    position: absolute;
    right: 142px;
    top: 34px;
    display: flex;
    .btn-unit {
      width: 24px;
      height: 24px;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #ccc !important;
      color: rgba(51, 51, 51, 0.87);

      &:first-child {
        border-radius: 6px 0 0 6px;
      }
      &:nth-child(2) {
        border-radius: 0 6px 6px 0;
      }
      &.active {
        background-color: #00897b;
        color: rgba(255, 255, 255, 0.87);
        &:hover {
          color: rgba(255, 255, 255, 0.87);
        }
      }
      &:hover {
        color: rgba(51, 51, 51, 0.87);
      }
    }
  }

  // 테이블 스타일
  table {
    td,
    th {
      &.tc-125 {
        width: 125px;
      }
      height: 32px;
      border-bottom: 1px solid #ccc;
    }
  }
  .MuiTable-root {
    box-sizing: border-box;
    //  margin-bottom: 10px;

    .MuiTableCell-root {
      box-sizing: border-box;
      letter-spacing: -0.05em;
    }
    .table-head {
      .td {
        height: 32px;
      }
      border-top: 2px solid #00897b;
      //    border-bottom: 1px solid #CCC;
    }
    .tr {
      height: 32px;
      letter-spacing: -0.05em;
      box-sizing: border-box;
    }
    .th {
      color: rgba(51, 51, 51, 0.87);
      font-weight: bold;
      font-size: 14px;
      padding: 0 0 0 10px;
      &.highlight {
        color: rgba(0, 137, 123, 0.87);
      }
      &.sub-th {
        padding-left: 19.5px;
        font-size: 12px;
      }
      &.lightgrey {
        color: #666;
      }
    }
    .td {
      box-sizing: border-box;
      // width: 365px;
      padding: 0 0 0 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      //      border-bottom: 1px solid #ccc;
      &.sub-td {
        font-size: 12px;
      }
    }
    .MuiTableCell-root.no-padding {
      padding: 0;
    }
  }
  .ResultOverview,
  .SiteAnalysis,
  .ArchitectureOverview {
    .MuiTable-root {
      box-sizing: border-box;
      width: 490px;
      //  margin-bottom: 10px;
    }
  }
  /* 1. 사업성 검토 요약 */
  .ResultOverview {
    > .sub-title {
      font-weight: bold;
      color: rgba(0, 137, 123, 0.87);
      margin: 30px auto 0px auto;
    }

    .inner-section {
      article:nth-child(2) {
        margin-left: 20px;
      }
      display: flex;
    }
    th {
      font-weight: normal;
    }
    .table-title {
      color: rgba(0, 0, 0, 0.87);
      font-weight: bold;
      font-size: 14px;
      padding: 4px 0;
      height: 24px;
      margin-top: 20px;
      line-height: 1.5em;
      &.table-title-margin-top {
        margin-top: 30px;
      }
    }
  }

  .SiteAnalysis,
  .ArchitectureOverview,
  .FloorDivision,
  .OfficeFloorDivision,
  .FloorSummary,
  .HouseholdSummary {
    .sub-title {
      font-weight: bold;
      color: rgba(0, 137, 123, 0.87);
    }

    .content-wraper {
      display: flex;
      align-items: flex-start;
    }
  }

  .ResultOverview,
  .FloorSummary,
  .HouseholdSummary,
  .BusinessFeasibility {
    tbody {
      tr {
        &:last-child {
          td {
            border-bottom: 1px solid #00897b;
          }
        }
      }
    }

    thead {
      tr {
        th {
          border-bottom: 1px solid #00897b;
        }
      }
    }
    tfoot {
      tr {
        td,
        th {
          //     border-bottom: 1px solid #CCC;
        }
      }
    }
  }
}
.SiteAnalysis {
  .sub-title {
    margin-bottom: 30px;
  }
  #mini-map {
    width: 374px;
    height: 374px;
    box-sizing: border-box;
    border: 2px solid #00897b;
  }
}

.SiteAnalysis,
.ArchitectureOverview {
  .MuiTable-root {
    width: 490px;
  }
  .MuiTableContainer-root {
    width: 490px;
    overflow: visible;
  }
  #mini-map,
  canvas {
    margin-left: 92px;
  }
  // .MuiIconButton-sizeSmall{
  //   padding: 0;
  //   padding-left: 3px;
  // }
  // ul.address-list{
  //   list-style: none;
  //   margin-left: 192px;
  //   padding: 0;
  // }
  // li{
  //   height: 2em;
  // }
}

/* 3. 건축개요*/
.ArchitectureOverview {
  display: relative;
  .sub-title {
    margin-bottom: 30px;
  }
}
/* 4. 층별 평면도*/
.FloorDivision {
  .sub-title {
    margin-bottom: 30px;
  }
  .MuiTableContainer-root {
    width: 1000px;
  }
  .content-wraper {
    justify-content: center;
    //   justify-content: space-around;
  }
  .result-img {
    width: 374px;
    height: 374px;
    box-sizing: border-box;
    border: 2px solid #00897b;
    margin: 0px 20px auto 20px;
  }
}
/* 4-1. 오피스텔 층별 평면도*/
.OfficeFloorDivision {
  .sub-title {
    margin-bottom: 30px;
  }
  .MuiTableContainer-root {
    width: 1000px;
  }
  .content-wraper {
    justify-content: center;
    //   justify-content: space-around;
  }
  .result-img {
    width: auto;
    height: 320px;
    box-sizing: border-box;
    border: 2px solid #00897b;
    margin: 10px;
  }
}

/* 5. 층별 개요 */
.FloorSummary {
  .sub-title {
    font-weight: bold;
    margin: 0 0 20px 0;
  }
  .MuiTableContainer-root {
    width: 1000px;
    .HouseholdSummary-table {
      width: 750px;
    }
  }
  .MuiTable-root {
    width: 1000px;
    .th {
      padding: 0;
    }
    .MuiTableCell-root {
      box-sizing: border-box;
      padding-left: 10px;
    }
  }
  margin-bottom: 40px !important;
  .connected-page-sub-title {
    margin-top: 40px !important;
  }
}

/* 6. 세대별 개요 */
.HouseholdSummary {
  .sub-title {
    margin-bottom: 20px;
  }
  .MuiTable-root {
    width: 750px;
  }
}
.HouseholdSummary:not(:last-child) {
  .MuiTable-root {
    margin-bottom: 40px;
  }
  margin-bottom: 0px;
}
.print-only {
  display: none;
}
// .HouseholdSummary:last-child {
//   .MuiTable-root {
//     margin-bottom: 0px;
//   }
//   margin-bottom: 80px !important;
// }

/* 7. 사업성 검토 */
.BusinessFeasibility {
  .sub-title {
    font-weight: 700;
    color: rgba(0, 137, 123, 0.87);
    margin: 40px auto 20px auto;
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start;
  }

  .content-wraper {
    display: flex;
    .sub-header {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      margin: 0px;
      height: 24px;
      padding: 0 0 4px 0;
    }
  }

  table {
    width: 490px;
  }

  .left-article {
    margin-right: 20px;
    .FlexContainer {
      display: flex;
      //      align-items: center;
      .input {
        font-size: 14px;
        width: 30px;
        text-align: center;
        border: none;
        border-bottom: 1px solid black;
        outline: none;
        color: rgba(0, 137, 123, 0.87);
      }
      .btn-outline {
        margin-left: 10px;
        width: 48px;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
  .right-article {
    position: relative;
    .MuiTextField-root {
      position: absolute;
      right: 0px;
      top: 335px;
      display: flex;
      padding: 0;
      flex-direction: row;
      .MuiFormLabel-root {
        width: 200px;
        color: #808080;
      }
      .MuiInput-root {
        width: 185px;
        color: #808080;
        &.MuiInput-focused {
        }
      }
    }

    .help-icon {
      width: 16px;
      height: 16px;
      color: #808080;
      position: absolute;
      right: 0px;
      top: 335px;
    }
  }
}
