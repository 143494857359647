.MapContent {
  float: left;
  position: relative;
  .btn-groups-left {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .btn-groups-right {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .main-right-logo {
    position: absolute;
    bottom: 30px;
    right: 10px;
  }
}
.info_container {
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #999;
  width: 80px;
  border-radius: 15px;
  z-index: 1000;
  .contents_price {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;
    color: #cc0;
    // background-color: #fff;
    justify-content: center;      
  }
  .content_date {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;
    color: #222;
    // background-color: #fff;
    justify-content: center;      
  }    
}
.info_container:after, .info_container:before {
  top: 100%;
	left: 50%;
	border: solid transparent;
  content:""; 
  height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;  
}

.info_container:after {
  border-color: rgba(255, 255, 255, 0);
	border-top-color: #fff;
	border-width: 5px;
	margin-left: -5px;
  // transform: rotate(180deg);
}

.info_container:before {
  border-color: rgba(226, 225, 245, 0);
	border-top-color: #555;
  border-width: 6px;
	margin-left: -6px;
}






.info_dark_container {
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #999;
  border: 1px solid #000;
  width: 70px;  
  border-radius: 15px;  
  .contents_price {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    // background-color: #fff;
    justify-content: center;      
  }
  .content_date {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 700;
    color: #222;
    // background-color: #fff;
    justify-content: center;      
  }    
}
.info_dark_container:after, .info_dark_container:before {
  top: 100%;
	left: 50%;
	border: solid transparent;
  content:""; 
  height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;  
}

.info_dark_container:after {
  border-color: rgba(255, 255, 255, 0);
	border-top-color: #999;
	border-width: 5px;
	margin-left: -5px;
  // transform: rotate(180deg);
}

.info_dark_container:before {
  border-color: rgba(226, 225, 245, 0);
	border-top-color: #000;
  border-width: 6px;
	margin-left: -6px;
}