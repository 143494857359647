.AddressSearchSection {
  // padding: 10px 0px;
  width: 90%;
  height: 32px;
  margin-top: 20px;
  background-color: #B2DFDB;
  z-index: 10;
  .address-input {
    width: 100%;
    // height: 32px;
    // box-sizing: border-box;
    background-color: #fff;
    border: '1px solid #B2DFDB';
    border-radius: 6px;
    // input {
    //   height: 40px;
    //   box-sizing: border-box;
    //   font-size: 16px;
    //   padding: 0px 0px 0px 8px;
    // }
  }
  .address-wrapper {
    background-color: white;
    // position: absolute;
    border: 1px solid #9698a3;
    max-height: 500px;
    overflow: auto;
    width: 99%;
    box-shadow: 1px 1px 1px 0px #999;
    text-align: left;
    &.empty {
      >.header {
        font-size: 12px;
        color: #999;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
      }
      >.body {
        font-size: 12px;
        color: #999;
        border-top: 1px solid #333;
      }
      >.footer {
        border-top: 1px solid #333;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-flow: row;
        align-items: center;
        color: #999;
        font-size: 12px;
        >.title {
          cursor: pointer;
        }
        >.delete {
          cursor: pointer;
          margin-left: auto;
        }
      }
      .item {
        padding-right: 10px;
        display: flex;
        flex-flow: row;
        align-items: center;        
        >.address {
          width: 310px;          
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .icon-btn {
          background-color: inherit;
          transition: none;
          .icon {
            color: #555;
          }
          &:hover {
            background-color: inherit;
            .icon {
              color: #ccc;
            }
          }
        }
      }
    }
    .item {
      box-sizing: border-box;
      padding: 0px 10px;
      height: 40px;
      display: flex;
      flex-flow: row nowrap;
      align-items: space-between;
      background-color: white;
      font-size: 13px;
      color: #004D40;
      cursor: pointer;
      width: 100%;
     
      >.body {        
        width: 80%;
        >.name {
          display: block;
          color: #004D40;
        }
        >.address {
          display: block;
          color: #999;
          font-size: 12px;
          
        }
      }
      >.keyword {
        width: 20%;
        font-size: 6px;
        color: #004D40;
        text-align: right;
        background-color: inherit;
      }
      >.log {
        padding: 0px 10px;
      }
      .highlight {
        color: #0000ff;
        font-weight: 700;
        background-color: inherit;
      }

      &:first-child {
        border-top: 0px;
      }
      &:hover, &.active {
        background-color: #B2DFDB;
      }


      &.place {
        height: 48px;
        padding-top: 10px;
      } 
      &.address {
        padding-top: 10px;
      }
      &.geocode {

      }
    }    
  }
}