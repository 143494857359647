.ProjectResult {
  font-family: Roboto;
  background-color: white;;
  width: 1200px;
  height: 695px; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 30px 30px 30px;
  align-items: flex-start;
  box-sizing: border-box;
  header {
    width: 1060px;
    margin: auto;
    height: 62px;
  }
  .print-btn {
    font-weight: normal;
    .MuiButton-label{
      font-size: 12px;      
      color: rgba(51, 51, 51, 0.87) !important;
      &:hover {
        font-weight: normal;
      }
    }
    position: absolute;
    top: 34px;
    right: 84px;
    line-height: 24px;
    z-index: 10000;
    width: 48px;
    min-width: 48px;
    height: 24px;
    border: 1px solid #ccc !important;
    color: rgba(51, 51, 51, 0.87) !important;
    &:hover {
      font-weight: normal; 
    }
  }

  .title-wrap {
    height: 32px;
    width: 1015px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .back-btn {
      right: 30px;
      position: absolute;
      top: 22px;
    }
    // .btn-wrap {
    //   margin-left: auto;
    //   .back-btn{
    //     // top: 34px;
    //     // right: 31px;
    //     // position: absolute;
    //   }
    // }
  }
  .sub-title {
    height: 24px;
    color: rgba(51, 51, 51, 0.87);
    font-size: 16px;
  }
  .failed-description{
    display : flex;
    height: 80%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}
