.App {
  .btn {
    box-sizing: border-box;
    &.btn-primary {
      background-color: #3f51b5;
      border: 1px solid #3f51b5;
      color: white;
      font-weight: bold;
      border-radius: 6px;
      padding: 2px 8px;
      &.btn-outline {
        color: #3f51b5;
        border: 1px solid #3f51b5;
        background-color: white;
        
      }
    }
  }  
  .select {
    select {
      padding: 8px 32px 8px 14px;
    }
  }

  .MuiTextField-root {
    .MuiInputLabel-outlined {
      transform: translate(14px, 10px) scale(1); 
      &.Mui-focused, &.MuiFormLabel-filled {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
    .MuiOutlinedInput-root {
      height: 35px;
      border-color: #00897B;
      
    }
  }
}