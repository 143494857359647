
.Login { 
  position: absolute;
  width: 100%;
  height: 100%;  
  top: 0px;
  left: 0px;
  background-image: url('../img/login_background.png');  
  background-color: #B2DFDB;
  background-blend-mode: soft-light;
  >.dim {
    height: 100% ; // calc(100vh - 60px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0px;
    z-index: 999999;
    >.circular-progress {
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 60px);
      color: #eee;
    }
  }
  .login-wrapper {    
    width: 1920px;
    height: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;    
    
    .loginConatainer {
      background-color: #fff;
      // background-image: url('../img/login_background.jpg');      
      // margin-bottom: 10px;
      width: 480px;    
      height: 343px;  
      // border: 1px solid #fff;
      border-radius: 0px;
      // flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      box-sizing: border-box;
      transform: translate(-50%, -50%);
      // justify-content: center;
      // align-items: center;
      // display: flex;      
      padding: 40px 50px;
      >.header {
        font-size: 24px;
        color: #000;
        line-height: 125%;
        font-weight: 700;
       // margin: 10px;
        margin-bottom: 0px;
        width: 100%; // 380px;

        // background-color: #666;
        // border: 2px solid #222;
        // border-radius: 15px;
      }
      >.loginheader {
        font-size: 16px;
        color: #000;
        line-height: 22px;
        font-weight: 700;
        margin: 10px auto;
        margin-bottom: 20px;
        width: 100%;
        border: none;
        background-color: #fff;      
      }

      .textInput {
        width: 380px;
        height: 48px;
        line-height: 48px;   
        margin-bottom: 15px;
        
      }
      .loginBtn {
        width: 380px;
        height: 40px;
        font-size: 18px;
        color:rgba(255, 255, 255, 0.87);
        font-weight: 700;
        margin-top: 15px;
        background-color: #00897B;
        border-radius: 6px;
      }
    }
    
  }  
  .logoDiv {
    position: absolute;
    left: calc(50% - 90px);
    bottom: 50px;
    width: 179px;
    height: 40px;
    background-image: url('../img/mainlogo.png');  
  }
}