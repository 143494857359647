.mainView {
  display: flex;
  .checkboxList {
    background-color: #232732;
    width: 120px;

    .reportBox {
      height: 40px;
      padding-left: 22.5px;
      color: #ccc;
      display: flex;
      // margin: auto;

      > .checkbox {
        padding: 0px;
        padding-right: 7.5px;
        color: #ccc;
        svg {
          font-size: 20px;
        }

        &.checked {
          color: #95e4b3;
        }
      }
      > .label {
        margin-top: 10px;
        margin-left: 0;
        font-size: 12px;
      }
    }
  }
}

.Visualizer {
  position: relative;

  .canvases {
    display: flex;
    flex-flow: row wrap;
    .Canvas {
      canvas {
        border: 2px solid #00897B;
        box-sizing: border-box;
      }
      position: relative;
      canvas {
        outline: none;
      }
    }
    .rightTop {
      // position: absolute;
      // right: 30px;
      // top: 90px;
      width: 374px;
      margin-left: 92px;
      display: flex;
      flex-direction: row-reverse;

      .compassImg0 {
        circle {
          fill: none;
        }
      }

      .compassButton {
        width: 40px;
        height: 40px;
        padding: 0px;
        border: 0px;
        margin-top: 10px;
        margin-left: 10px;
        background: none;
        cursor: pointer;
        outline: none;
      }

      .imageButton {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: #383b45;
        border-radius: 6px;
        display: flex;
        margin-top: 10px;
        margin-left: 10px;
        .image {
          margin: auto;
          color: #eee;
        }

        &.hidden {
          visibility: hidden;
        }

        &.active {
          .image {
            color: #95e4b3;
          }
        }

        .imageIcon {
          margin: auto;
          width: 24px;
          height: 24px;
        }
      }

      .reportNum {
        text-align: center;
      }
    }
  }
}

.modalBody {
  div {
    > div {
      width: 985px;
      height: 640px;
      max-width: 985px;
      background-color: #1a1e28;
    }
  }

  .modal-title {
    background-color: #1a1e28;
    box-sizing: border-box;
    width: 985px;
    height: 50px;
    padding: 30px 40px 0 30px;
    font-size: 18px;
    color: #eee;
    display: flex;
    .title {
      width: auto;
      height: 20px;
    }

    .icon {
      color: #555555;
      margin-left: auto;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .project_info {
    display: flex;
    max-width: 955px;
    height: auto;
    padding-top: 21px;
    padding-left: 30px;

    .info_parameter {
      width: 175px;
      height: 40px;
      display: flex;
      flex-direction: column;
      // padding-left: 10px;

      .info_title {
        width: auto;
        height: 20px;
        font-size: 12px;
        color: #999;
      }

      .info_value {
        width: auto;
        font-size: 14px;
        color: #ccc;
        height: 20px;
      }

      &.w175 {
        width: 175px;
      }

      &.w360 {
        width: 360px;
      }

      &.rb {
        border-right: 1px solid #333;
        margin-right: 10px;
      }
    }
  }
}

.loading {
  width: 374px;
  height: 374px;
  display: flex;
  margin-left: 92px;

  .information {
    // position: absolute;
    // transform: translate(-50%, -50%);
    // top: 50%;
    // left: 50%;
    margin: auto;
    text-align: center;
    z-index: 1;

    .progress {
      width: 200px;
      height: 200px;
      animation: rotation 1.33s infinite linear;
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }

    // .centerWord {
    //   transform: translate(-50%, -100%);
    //   top: 50%;
    //   left: 50%;
    //   position: absolute;
    //   width: 130px;
    //   font-family: Noto Sans KR;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 24px;
    //   line-height: 28px;
    //   text-align: center;
    //   color: #eeeeee;
    // }

    // .loadingMessage {
    //   padding-top: 30px;
    //   font-family: Noto Sans KR;
    //   font-style: normal;
    //   font-weight: bold;
    //   font-size: 18px;
    //   line-height: 26px;
    //   text-align: center;
    //   color: #48ddf2;
    // }
  }
}
